import { HTTP_STATUSES } from '@/constants/common'
import { DEALERS_TYPES } from '@/constants/dealers'
import delivery from '@/delivery'
import { camelToSnakeCase } from '@/helpers/convertString'
import { apiRequestWrapper } from '@/helpers/delivery'
import ApiConnector from '../connector'

class DealersActions {
  getList = async (query) => {
    const params = {
      page: query.page || 1,
      limit: query.limit || 20,
    }
    if (query.search) {
      params.search = query.search
    }
    if (query.searchRecipients) {
      params.search_recipients = query.searchRecipients
    }
    if (query.searchRequisites) {
      params.search_requisites = query.searchRequisites
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }
    if (query.isBanned !== null) {
      params.is_banned = query.isBanned
    }
    if (query.ieCartDiscountIsAvailable !== null) {
      params.ie_cart_discount_is_available = query.ieCartDiscountIsAvailable
    }
    if (query.llcCartDiscountIsAvailable !== null) {
      params.llc_cart_discount_is_available = query.llcCartDiscountIsAvailable
    }
    if (query.rwCartDiscountIsAvailable !== null) {
      params.rw_cart_discount_is_available = query.rwCartDiscountIsAvailable
    }
    if (query.memberBP !== null) {
      params.is_bonus_program_member = query.memberBP
    }
    if (query.isSupplier !== null) {
      params.is_supplier = query.isSupplier
    }
    if (query?.supplier?.length) {
      params.supplier = query.supplier
    }
    if (query.type) {
      params.type = query.type
    }
    if (query.pricePolicyId) {
      params.price_policy_id = query.pricePolicyId
    }
    if (query.dealerCatalogId) {
      params.dealer_catalog_id = query.dealerCatalogId
    }
    if (query.managerId) {
      params.manager_account_id = query.managerId
    }
    if (query.managerCoordinatorId) {
      params.created_manager_id = query.managerCoordinatorId
    }
    if (query.startAt) {
      params.crated_at_start = query.startAt
    }
    if (query.endAt) {
      params.crated_at_end = query.endAt
    }

    if (query.notExcludedFromBonusProgramId) {
      params.not_excluded_from_bonus_program_id =
        query.notExcludedFromBonusProgramId
    }

    if (query.excludedFromBonusProgramId) {
      params.excluded_from_bonus_program_id = query.excludedFromBonusProgramId
    }

    if (query.withoutPagination) {
      params.withoutPagination = query.withoutPagination
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/dealer-account`, {
        params,
      }),
    )
  }

  getListWithBonusCount = async (query) => {
    const params = {
      page: query.page || 1,
      limit: query.limit || 20,
    }
    if (query.search) {
      params.search = query.search
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/dealer-account/bonus-count`, {
        params,
      }),
    )
  }
  getById = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/dealer-account/${id}`),
    )
  }
  create = async (data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/dealer-account`, data),
      201,
    )
  }
  update = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(`/v1/admin/dealer-account/${id}`, data),
      200,
    )
  }

  patch = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.patch(`/v1/admin/dealer-account/${id}`, data),
    )
  }

  delete = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(`/v1/admin/dealer-account/${id}`),
      204,
    )
  }
  getDealersOrders = async (query) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/dealer-account/${query.id}/orders?page=${query.page}&limit=${query.limit}`,
      ),
    )
  }
  getDealersDeliveryAddresses = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/dealer-account/${id}/delivery-addresses`,
      ),
    )
  }
  createDealerDeliveryAddress = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(
        `/v1/admin/dealer-account/${id}/delivery-addresses`,
        data,
      ),
      201,
    )
  }
  updateDealerDeliveryAddress = async (id, addressId, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(
        `/v1/admin/dealer-account/${id}/delivery-addresses/${addressId}`,
        data,
      ),
      200,
    )
  }
  deleteDeliveryAddress = async (id, addressId) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(
        `/v1/admin/dealer-account/${id}/delivery-addresses/${addressId}`,
      ),
      204,
    )
  }
  getDealerDeliveryAddress = async (id, addressId) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/dealer-account/${id}/delivery-addresses/${addressId}`,
      ),
    )
  }
  getClientRequesites = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/dealer-account/${id}/client-requesites`,
      ),
    )
  }
  createClientRequesites = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(
        `/v1/admin/dealer-account/${id}/client-requesites`,
        data,
      ),
      201,
    )
  }
  deleteClientRequesites = async (id, requesitesId) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(
        `/v1/admin/dealer-account/${id}/client-requesites/${requesitesId}`,
      ),
      204,
    )
  }

  getSearchList = async (query) => {
    const params = {
      is_banned: query.isBanned,
      limit: query.limit,
      search: query.search,
      search_recipients: query.searchRecipients,
      search_requisites: query.searchRequisites,
    }
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/dealer-account`, { params }),
    )
  }

  getDealersCount = async () => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/dealer-account/count`),
    )
  }

  getDealerRecipients = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/dealer-account/${id}/recipients`),
      200,
    )
  }

  getDealerRecipient = async (id, recipientId) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/dealer-account/${id}/recipients/${recipientId}`,
      ),
      200,
    )
  }

  createDealerRecipient = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(
        `v1/admin/dealer-account/${id}/recipients`,
        data,
      ),
      201,
    )
  }

  updateDealerRecipient = async (id, recipientId, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.put(
        `v1/admin/dealer-account/${id}/recipients/${recipientId}`,
        data,
      ),
      200,
    )
  }

  deleteDealerRecipient = async (id, recipientId) => {
    return await apiRequestWrapper(
      ApiConnector.connector.delete(
        `/v1/admin/dealer-account/${id}/recipients/${recipientId}`,
      ),
      204,
    )
  }

  getBonusTransactions = async (id, query) => {
    const params = {
      page: query.page || 1,
      limit: query.limit || 20,
    }
    if (query.search) {
      params.search = query.search
    }
    if (query.orderBy) {
      params.order_by = camelToSnakeCase(query.orderBy)
    }
    if (query.orderDir) {
      params.order_dir = query.orderDir
    }

    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/dealer-account/${id}/bonus-transactions`,
        { params },
      ),
    )
  }

  getBonusTransactionsCount = async (id) => {
    return await apiRequestWrapper(
      ApiConnector.connector.get(
        `/v1/admin/dealer-account/${id}/bonus-transactions/count`,
      ),
    )
  }

  createDealerNote = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`v1/admin/dealer-account/${id}/notes`, data),
      201,
    )
  }

  getDealersFilters = async () => {
    const limit = 10
    const result = {
      types: [],
      managersCoordinators: [],
      managers: [],
      pricePolicies: [],
    }
    await Promise.all([
      await delivery.AddwineCore.ManagersActions.getList({
        page: 1,
        limit,
        coordinators: true,
      }),
      await delivery.AddwineCore.ManagersActions.getList({
        page: 1,
        limit,
        coordinators: false,
      }),
      await delivery.AddwineCore.PricePolicyActions.getList({
        page: 1,
        limit,
      }),
      await delivery.AddwineCore.CatalogsActions.getList({
        page: 1,
        limit,
      }),
    ]).then((results) => {
      const [
        managersCoordinatorsData,
        managersData,
        pricesPolicyData,
        catalogListData,
      ] = results
      result.types = DEALERS_TYPES
      if (!managersData.error && managersData.value?.data?.length) {
        result.managers = {
          data: [
            {
              name: 'По умолчанию',
              id: 'default',
            },
            ...managersData.value?.data,
          ],
          meta: { page: 1, count: managersData.value.meta.count },
        }
      }
      if (
        !managersCoordinatorsData.error &&
        managersCoordinatorsData.value?.data?.length
      ) {
        result.managersCoordinators = {
          data: [
            {
              name: 'По умолчанию',
              id: 'default',
            },
            ...managersCoordinatorsData.value?.data,
          ],
          meta: { page: 1, count: managersCoordinatorsData.value.meta.count },
        }
      }

      if (!pricesPolicyData.error && pricesPolicyData.value?.data?.length) {
        result.pricesPolicy = {
          data: [
            {
              name: 'По умолчанию',
              id: 'default',
            },
            ...pricesPolicyData.value?.data,
          ],
          meta: { page: 1, count: pricesPolicyData.value.meta.count },
        }
      }
      if (!catalogListData.error && catalogListData.value?.data?.length) {
        result.catalogList = {
          data: [
            {
              name: 'По умолчанию',
              id: 'default',
            },
            ...catalogListData.value?.data,
          ],
          meta: { page: 1, count: catalogListData.value.meta.count },
        }
      }
    })
    return result
  }

  getNotesList = async (id, query) => {
    const params = {
      page: query.page || 1,
      limit: query.limit || 20,
    }
    if (query.preload_dealers) {
      params.preload_dealers = true
    }
    return await apiRequestWrapper(
      ApiConnector.connector.get(`/v1/admin/dealer-account/${id}/notes`, {
        params,
      }),
    )
  }

  createNote = async (id, data) => {
    return await apiRequestWrapper(
      ApiConnector.connector.post(`/v1/admin/dealer-account/${id}/notes`, data),
      HTTP_STATUSES.CREATED,
    )
  }
}

export default new DealersActions()
